import { IVideoYouTubeFields } from 'contentful-types'

import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'

import VideoYouTube, { VideoYouTubeProps } from './VideoYouTube'
import { getYouTubeVideoIdFromUrl } from './getYouTubeVideoIdFromUrl'

const ContentfulWebVideo = withContentful<IVideoYouTubeFields, VideoYouTubeProps>(VideoYouTube, {
  videoId: (props) => getYouTubeVideoIdFromUrl(props.fields?.videoId),
  posterSrc: (props) => props.fields?.posterSrc?.fields?.file?.url,
})

export default ContentfulWebVideo
