import { useViewport } from '@telus-uds/components-web'
import { useWindowSize } from './useWindowSize'

const useBBViewport = () => {
  // on initial load useViewport is "xs" even on "lg" devices.
  // useWindowSize starts as undefined. Both resolve at the same time
  // and then we can use the useViewport defined viewports
  // this is a temp solution until this gets resolved: https://github.com/telus/universal-design-system/issues/1480
  const viewport = useViewport()
  const windowSize = useWindowSize()

  const finalViewPort = windowSize.width ? viewport : null
  return finalViewPort
}

export default useBBViewport
