import { Video } from '../blocks/core/VideoPicker/VideoPicker'
import { changeAdobeDataLayer } from './alterDataLayers'

export enum EventType {
  PLAY = 'videoStart',
  PAUSE = 'videoPause',
  RESUME = 'videoResume',
  COMPLETE = 'videoComplete',
}

export enum MediaTimed {
  PLAY = 'starts',
  PAUSE = 'pauses',
  RESUME = 'resumes',
  COMPLETE = 'completes',
}

export type YoutubeVideoAnalytics = {
  event: EventType
  eventInfo: VideoEventInfo
  xdm: XDM
}

type VideoEventInfo = 'Video play event' | 'Video pause event' | 'Video resume event' | 'Video complete event'

const videoEventInfoMap: Record<EventType, VideoEventInfo> = {
  [EventType.PLAY]: 'Video play event',
  [EventType.PAUSE]: 'Video pause event',
  [EventType.RESUME]: 'Video resume event',
  [EventType.COMPLETE]: 'Video complete event',
}

const videoEventMediaTimedMap: Record<EventType, MediaTimed> = {
  [EventType.PLAY]: MediaTimed.PLAY,
  [EventType.PAUSE]: MediaTimed.PAUSE,
  [EventType.RESUME]: MediaTimed.RESUME,
  [EventType.COMPLETE]: MediaTimed.COMPLETE,
}

export type XDM = {
  media: Media
}

export type Media = {
  _telus: Telus
  mediaTimed: any
}

export type Telus = {
  videoName: string
  videoID: string
  videoURL: string
  videoPlayhead: string
  videoDuration: string
  videoPlayer: string
}

export type Value = {
  value: number
}

export const generateAnalyticsObjectForYoutubeEvent = (type: EventType, videoId: string, player: any) => {
  const videoPlayhead = player.getCurrentTime()
  const videoDuration = player.getDuration()
  const videoName = player.getVideoData().title

  const url = `https://www.youtube.com/watch?v=${videoId}`

  const xdm: XDM = {
    media: {
      _telus: {
        videoName,
        videoID: videoId,
        videoURL: url,
        videoPlayhead,
        videoDuration,
        videoPlayer: 'YouTube',
      },
      mediaTimed: {
        [videoEventMediaTimedMap[type]]: {
          value: videoPlayhead,
        },
      },
    },
  }
  return { event: type, eventInfo: videoEventInfoMap[type], xdm }
}

export const addAnalyticsCallbacksToVideos = (videoList: Video[], isAnalyticsTriggered: boolean) => {
  if (!videoList) {
    return []
  }
  return videoList.map((video) => {
    const { videoId } = video

    const onPlayEvent = (event: any) => {
      changeAdobeDataLayer(
        generateAnalyticsObjectForYoutubeEvent(EventType.PLAY, videoId, event.target),
        isAnalyticsTriggered
      )
    }

    const onPauseEvent = (event: any) => {
      changeAdobeDataLayer(
        generateAnalyticsObjectForYoutubeEvent(EventType.PAUSE, videoId, event.target),
        isAnalyticsTriggered
      )
    }

    const onEndEvent = (event: any) => {
      changeAdobeDataLayer(
        generateAnalyticsObjectForYoutubeEvent(EventType.COMPLETE, videoId, event.target),
        isAnalyticsTriggered
      )
    }

    return {
      ...video,
      onPlay: onPlayEvent,
      onPause: onPauseEvent,
      onEnd: onEndEvent,
    }
  })
}
