export const changeDataLayer = (analyticsEvent, isAnalyticsTriggered: boolean) => {
  if (analyticsEvent && isAnalyticsTriggered && typeof window === 'object' && 'dataLayer' in window) {
    window.dataLayer.event = analyticsEvent
  }
}

export const changeAdobeDataLayer = (analyticsEvent, isAnalyticsTriggered: boolean) => {
  if (analyticsEvent && isAnalyticsTriggered && typeof window === 'object' && 'adobeDataLayer' in window) {
    window.adobeDataLayer.push(analyticsEvent)
  }
}
